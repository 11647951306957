import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import PerfectScrollbar from 'react-perfect-scrollbar';

import InputItem from '../Forms/InputItem/InputItem';

import ElectionIcon from '../../../assets/images/icons/election.svg';
import TrophyBigIcon from '../../../assets/images/icons/trophy-big.svg';
import TrophySmallIcon from '../../../assets/images/icons/trophy-small.svg';
import './ElectionsTable.scss';

const ElectionsTable = ({ number, electionDetails, setElectionDetails, setShowElection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleElectionClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleElectionDelete = () => {
    setShowElection(false);
    setElectionDetails({
      election: '',
      winner: '',
      prevWinner: '',
      candidates: [{ party: '', candidate: '', votes: '', percent: '', percentChange: '', expenditures: '' }],
      validVotes: { total: '', percent: '', percentChange: '' },
      rejectedVotes: { total: '', percent: '', percentChange: '' },
      turnout: { total: '', percent: '', percentChange: '' },
      expenseLimit: '',
      eligibleVoters: '',
      swing: '',
    });
  };

  const handleInputChange = (field, value) => {
    setElectionDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const addCandidate = () => {
    setElectionDetails((prevDetails) => ({
      ...prevDetails,
      candidates: [
        ...prevDetails.candidates,
        { party: '', candidate: '', votes: '', percent: '', percentChange: '', expenditures: '' },
      ],
    }));
  };

  const removeCandidate = (index) => {
    setElectionDetails((prevDetails) => ({
      ...prevDetails,
      candidates: prevDetails.candidates.filter((_, i) => i !== index),
    }));
  };

  const handleCandidateChange = (index, field, value) => {
    setElectionDetails((prevDetails) => {
      const updatedCandidates = [...prevDetails.candidates];
      updatedCandidates[index][field] = value;
      return { ...prevDetails, candidates: updatedCandidates };
    });
  };

  const handleElectionDetailChange = (field, value) => {
    setElectionDetails((prevState) => {
      const keys = field.split('.');
      const newElectionDetails = { ...prevState };

      let current = newElectionDetails;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }

      current[keys[keys.length - 1]] = value;

      return newElectionDetails;
    });
  };

  const onWheel = (e) => {
    e.target.blur();
  };

  return (
    <div className={`election ${isOpen ? 'open' : ''}`}>
      <div className="election-line" onClick={handleElectionClick}>
        {`Election #${number}${!isOpen && electionDetails.election ? ` (${electionDetails.election})` : ''}`}
        <div className="election-actions">
          {number === 2 && (
            <span className="election-btn" onClick={handleElectionDelete}>
              Delete
            </span>
          )}
          <svg
            className={`arrow ${isOpen ? 'rotated' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M6 10L12 16L18 10" stroke="#000000" strokeLinecap="round" />
          </svg>
        </div>
      </div>
      {isOpen ? (
        <div className="election-wrap">
          <div className="election-fields">
            <InputItem
              label="Election name"
              type="text"
              icon={ElectionIcon}
              onChange={(e) => handleInputChange('election', e.target.value)}
              value={electionDetails.election}
            />
            <InputItem
              label="Winning party"
              type="text"
              icon={TrophyBigIcon}
              onChange={(e) => handleInputChange('winner', e.target.value)}
              value={electionDetails.winner}
            />
            <InputItem
              label="Previous winning party"
              type="text"
              icon={TrophySmallIcon}
              onChange={(e) => handleInputChange('prevWinner', e.target.value)}
              value={electionDetails.prevWinner}
            />
          </div>
          <div className="election-table">
            <PerfectScrollbar
              options={{
                wheelPropagation: true,
                autoHide: false,
              }}
            >
              <Table bordered>
                <thead>
                  <tr>
                    <th>Party</th>
                    <th>Candidate</th>
                    <th>Votes</th>
                    <th>%</th>
                    <th>±%</th>
                    <th>Expenditures</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {electionDetails.candidates.map((candidate, index) => (
                    <tr key={index} className="candidate">
                      <td>
                        <input
                          type="text"
                          value={candidate.party}
                          onChange={(e) => handleCandidateChange(index, 'party', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={candidate.candidate}
                          onChange={(e) => handleCandidateChange(index, 'candidate', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={candidate.votes}
                          onChange={(e) => handleCandidateChange(index, 'votes', e.target.value)}
                          onWheel={onWheel}
                        />
                      </td>
                      <td>
                        <input
                          className="col5"
                          type="text"
                          value={candidate.percent}
                          onChange={(e) => handleCandidateChange(index, 'percent', e.target.value)}
                          onWheel={onWheel}
                        />
                      </td>
                      <td>
                        <input
                          className="col5"
                          type="text"
                          value={candidate.percentChange}
                          onChange={(e) => handleCandidateChange(index, 'percentChange', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={candidate.expenditures}
                          onChange={(e) => handleCandidateChange(index, 'expenditures', e.target.value)}
                          onWheel={onWheel}
                        />
                      </td>
                      <td>
                        <button className="edit-btn" type="button" onClick={() => removeCandidate(index)}>
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="disabled" colSpan="6"></td>
                    <td>
                      <button className="edit-btn save" type="button" onClick={addCandidate}>
                        Add
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">Total valid votes/Expense limit</td>
                    <td>
                      <input
                        type="number"
                        value={electionDetails.validVotes.total}
                        onChange={(e) => handleElectionDetailChange('validVotes.total', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.validVotes.percent}
                        onChange={(e) => handleElectionDetailChange('validVotes.percent', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.validVotes.percentChange}
                        onChange={(e) => handleElectionDetailChange('validVotes.percentChange', e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={electionDetails.expenseLimit}
                        onChange={(e) => handleElectionDetailChange('expenseLimit', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td className="disabled" colSpan="2"></td>
                  </tr>
                  <tr>
                    <td colSpan="2">Total rejected, unmarked, and declined ballots</td>
                    <td>
                      <input
                        type="number"
                        value={electionDetails.rejectedVotes.total}
                        onChange={(e) => handleElectionDetailChange('rejectedVotes.total', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.rejectedVotes.percent}
                        onChange={(e) => handleElectionDetailChange('rejectedVotes.percent', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.rejectedVotes.percentChange}
                        onChange={(e) => handleElectionDetailChange('rejectedVotes.percentChange', e.target.value)}
                      />
                    </td>
                    <td className="disabled" colSpan="2"></td>
                  </tr>
                  <tr>
                    <td colSpan="2">Turnout</td>
                    <td>
                      <input
                        type="number"
                        value={electionDetails.turnout.total}
                        onChange={(e) => handleElectionDetailChange('turnout.total', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.turnout.percent}
                        onChange={(e) => handleElectionDetailChange('turnout.percent', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.turnout.percentChange}
                        onChange={(e) => handleElectionDetailChange('turnout.percentChange', e.target.value)}
                      />
                    </td>
                    <td className="disabled" colSpan="2"></td>
                  </tr>
                  <tr>
                    <td colSpan="2">Eligible voters</td>
                    <td>
                      <input
                        type="number"
                        value={electionDetails.eligibleVoters}
                        onChange={(e) => handleElectionDetailChange('eligibleVoters', e.target.value)}
                        onWheel={onWheel}
                      />
                    </td>
                    <td className="disabled" colSpan="4"></td>
                  </tr>
                  <tr>
                    <td colSpan="4">Swing</td>
                    <td>
                      <input
                        className="col5"
                        type="text"
                        value={electionDetails.swing}
                        onChange={(e) => handleElectionDetailChange('swing', e.target.value)}
                      />
                    </td>
                    <td className="disabled" colSpan="2"></td>
                  </tr>
                </tbody>
              </Table>
            </PerfectScrollbar>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ElectionsTable;
