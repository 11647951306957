import React, { forwardRef, useState } from 'react';

import openEye from '../../../../assets/images/icons/eye-open.svg';
import closedEye from '../../../../assets/images/icons/eye-closed.svg';
import './InputItem.scss';

const InputItem = forwardRef(
  (
    { type = 'text', label = '', icon = false, onChange, value = '', onKeyDown, className = '', disabled = false },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleEyeChange = () => {
      setShowPassword((state) => !state);
    };

    return (
      <div className="input">
        <div className="input-icon">
          <img src={icon} />
        </div>
        <input
          ref={ref}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          placeholder={label}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          className={className}
          disabled={disabled}
        />
        {type === 'password' && (
          <div className="eye-icon">
            <img src={showPassword ? openEye : closedEye} onClick={handleEyeChange} />
          </div>
        )}
      </div>
    );
  },
);

export default InputItem;
