import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Table from '../../components/Table/Table';
import FormButton from '../../components/Forms/FormButton/FormButton';
import InputItem from '../../components/Forms/InputItem/InputItem';
import Fader from '../../components/Loaders/Fader';
import WarningPopup from '../../components/Popup/Warning';

import { BACKGROUND_EFFECT, DATA_TABLE_HEADER } from '../../../util/constants/AppConstants';
import { setBackgroundEffect } from '../../../datastore/actions/settingsActions';
import { setNotify } from '../../../datastore/actions/actionActions';
import { saveCustomState } from '../../../util/LocalStorage';
import { createTopic, deleteTopic, getLatestData, getTopic, getTopics, saveTopic } from '../../../http/dataAPI';
import useOutsideClick from '../../../util/useOutsideClick';

import TopicIcon from '../../../assets/images/icons/topic.svg';
import MentionsIcon from '../../../assets/images/icons/mentions.svg';
import AddIcon from '../../../assets/images/icons/add-white.svg';
import IdIcon from '../../../assets/images/icons/id.svg';
import './Searches.scss';

const Searches = () => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const backgroundEffectFormInputProps = { inputProps: { 'aria-label': 'Background Colour' } };
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);
  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);

  const [isSearchesDropdownOpen, setIsSearchesDropdownOpen] = useState(false);
  const [isCreateNewSearchOpen, setIsCreateNewSearchOpen] = useState(false);
  const [isCreatingSearch, setIsCreatingSearch] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(true);
  const [isSearchEdit, setIsSearchEdit] = useState(false);
  const [isSearchSaving, setIsSearchSaving] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [newSearchName, setNewSearchName] = useState('');
  const [newTwId, setNewTwId] = useState('');
  const [twId, setTwId] = useState('');

  const [searches, setSearches] = useState(null);
  const [searchId, setSearchId] = useState(0);
  const [defaultSearchName, setDefaultSearchName] = useState(null);

  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [sourceData, setSourceData] = useState(null);

  const dropdownRef = useRef();
  const dropdownField = useRef();
  const editInputRef = useRef();
  const createWindowRef = useRef();

  const handleChangeBackgroundStatus = (status) => {
    saveCustomState(BACKGROUND_EFFECT, status);
    dispatch(setBackgroundEffect(status));
  };

  const handleGetSourceData = async (id) => {
    setDataPreviewLoading(true);
    const res = await getLatestData(config, id);

    if (res.status === 'success') {
      setSourceData(res.data);
      setDataPreviewLoading(false);
    } else {
      console.log('ERROR: Grabbing Latest Data');
    }
  };

  const handleGetSearchInfo = async (id) => {
    setIsSearchLoading(true);

    const res = await getTopic(config, id);

    if (res.status === 200) {
      setTwId(res.data.twId || 'No Saved Talkwalker ID');
      setIsSearchLoading(false);
    } else {
      console.log('ERROR: Grabbing Search');
    }
  };

  const handleGetSearches = async () => {
    const res = await getTopics(config);

    if (res.status === 200) {
      setSearches(res.data);
      if (res.data.length > 0) {
        const firstSearch = res.data[0];
        setDefaultSearchName(firstSearch.name);
        setSearchId(firstSearch.id);
        handleGetSourceData(firstSearch.id);
        handleGetSearchInfo(firstSearch.id);
      }
    } else {
      console.log('ERROR: Grabbing Topics');
    }
  };

  const handleGetSearchSource = (id) => {
    setIsSearchEdit(false);
    setIsSearchesDropdownOpen(false);
    setSearchId(id);
    handleGetSourceData(id);
    handleGetSearchInfo(id);
    const selectedSearch = searches.find((item) => item.id === id);
    if (selectedSearch) {
      setDefaultSearchName(selectedSearch.name);
    }
  };

  const handleEditSearch = () => {
    if (isSearchEdit) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: 'You have successfully saved the Talkwalker ID.',
        }),
      );
      setIsSearchEdit(false);
      return;
    }
    setNewTwId(twId === 'No Saved Talkwalker ID' ? '' : twId);
    setIsSearchEdit(true);
  };

  useEffect(() => {
    if (isSearchEdit && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [isSearchEdit]);

  const handleDeleteSearch = async () => {
    const res = await deleteTopic(config, searchId);

    if (res.status === 200) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
      handleGetSearches();
      setDeleteRequest(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.data.message,
        }),
      );
    }
  };

  const hanldeSaveSearch = async () => {
    setIsSearchSaving(true);
    const formattedTwId = newTwId === 'No Saved Talkwalker ID' || newTwId === '' ? null : newTwId;

    const res = await saveTopic(config, searchId, formattedTwId);

    if (res.status === 200) {
      setTwId(newTwId === '' ? 'No Saved Talkwalker ID' : newTwId);
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.message,
        }),
      );
    }

    setIsSearchSaving(false);
    setIsSearchEdit(false);
  };

  const openCreateWindow = () => {
    setIsCreateNewSearchOpen(true);
    setIsSearchesDropdownOpen(false);
  };

  const clearSearchForm = () => {
    setNewSearchName('');
    setNewTwId('');
  };

  const handleCreateSearch = async () => {
    if (newSearchName) {
      setIsCreatingSearch(true);

      const res = await createTopic(config, newSearchName, newTwId);

      if (res.status === 201) {
        dispatch(
          setNotify({
            status: true,
            type: 'success',
            title: 'Success',
            message: res.data.message,
          }),
        );
        clearSearchForm();
        setIsCreateNewSearchOpen(false);
        handleGetSearches();
      } else {
        dispatch(
          setNotify({
            status: true,
            type: 'error',
            title: 'Error',
            message: res.message,
          }),
        );
      }

      setIsCreatingSearch(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Fill in the field with the search name',
        }),
      );
    }
  };

  const sourceTableColumns = useMemo(() => DATA_TABLE_HEADER, []);

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  useEffect(() => {
    if (config.api) {
      handleGetSearches();
    }
  }, [config]);

  useOutsideClick(createWindowRef, null, isCreateNewSearchOpen, () => setIsCreateNewSearchOpen(false));
  useOutsideClick(dropdownRef, dropdownField, isSearchesDropdownOpen, () => setIsSearchesDropdownOpen(false));

  return (
    <div className="dashboard-components">
      <div className="dc-left full">
        <div className="search-selection">
          <div className={isSearchesDropdownOpen ? 'ss-object open' : 'ss-object'}>
            {!isCreatingSearch ? (
              <>
                {searches && (
                  <span ref={dropdownField} onClick={() => setIsSearchesDropdownOpen((prevState) => !prevState)}>
                    <img src={TopicIcon} />
                    {defaultSearchName}
                  </span>
                )}
                {isSearchesDropdownOpen && (
                  <div ref={dropdownRef} className="ss-dropdown">
                    <div className={`ss-dropdown-list n${searches.length}`}>
                      <PerfectScrollbar
                        options={{
                          wheelPropagation: false,
                          autoHide: false,
                          suppressScrollX: true,
                        }}
                      >
                        {searches
                          ? searches.map((name) => (
                              <span onClick={() => handleGetSearchSource(name.id)}>
                                <img src={TopicIcon} />
                                {name.name}
                              </span>
                            ))
                          : ''}
                      </PerfectScrollbar>
                    </div>
                    <span className="create-new" onClick={openCreateWindow}>
                      <img src={AddIcon} />
                      {isCreateNewSearchOpen ? <></> : 'Create New'}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <div className="creating-source-fader">
                <Fader />
              </div>
            )}
          </div>
        </div>

        <div className="section-components top-space">
          <div className="sentiment-source chart-component full-width">
            <div className="title-top">
              <div className="tt-left">
                <div className="chart-title align-left">Talkwalker ID</div>
              </div>
              {!isSearchLoading && (
                <div className="tt-right">
                  {isSearchEdit ? (
                    <span className="edit-btn-cancel" onClick={() => setIsSearchEdit(false)}>
                      Cancel
                    </span>
                  ) : (
                    <div className="edit-btn warning" onClick={() => setDeleteRequest(true)}>
                      Delete
                    </div>
                  )}
                  <div
                    className={isSearchEdit ? 'edit-btn save' : 'edit-btn'}
                    onClick={isSearchEdit ? hanldeSaveSearch : handleEditSearch}
                  >
                    {isSearchEdit ? isSearchSaving ? <Fader /> : 'Save' : 'Edit'}
                  </div>
                </div>
              )}
            </div>
            {isSearchLoading ? (
              'Loading..'
            ) : isSearchEdit ? (
              <InputItem
                ref={editInputRef}
                label="Talkwalker ID"
                type={'text'}
                icon={IdIcon}
                onChange={(e) => setNewTwId(e.target.value)}
                value={newTwId}
                className="edit"
              />
            ) : (
              <InputItem label="Talkwalker ID" type={'text'} icon={IdIcon} value={twId} disabled={true} />
            )}
          </div>
        </div>

        <div className="sentiment-trend chart-component chart-extend">
          <div className="title-top">
            <div className="tt-left">
              <div className="chart-title align-left">Latest Data</div>
            </div>
          </div>

          {!dataPreviewLoading ? (
            <Table columns={sourceTableColumns} data={sourceData} />
          ) : (
            <div className="sentiment-charts">
              <Fader />
            </div>
          )}
        </div>
      </div>

      {isCreateNewSearchOpen && (
        <div className="login-wrap user-add-wrap">
          <div className="login-view">
            <div ref={createWindowRef} className="login-box add-user-box">
              <div className="logo-large">Create Search</div>
              <div className="searchForm">
                <InputItem
                  label="Search Name"
                  type={'text'}
                  icon={MentionsIcon}
                  onChange={(e) => setNewSearchName(e.target.value)}
                  value={newSearchName}
                />
                <InputItem
                  label="Talkwalker ID"
                  type={'text'}
                  icon={IdIcon}
                  onChange={(e) => setNewTwId(e.target.value)}
                  value={newTwId}
                />
              </div>
              <div className="submit delete">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={() => setIsCreateNewSearchOpen(false)}
                  text={'Save'}
                  onClick={handleCreateSearch}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteRequest && (
        <WarningPopup
          isOpen={deleteRequest}
          onSubmitHandler={handleDeleteSearch}
          onCancelHandler={() => setDeleteRequest(false)}
          title="Deleting Search"
        />
      )}
    </div>
  );
};

export default Searches;
