import { apiRequest, apiRequestDev } from './apiRequest';

export const getTopics = async (config) => apiRequestDev(config, '/data/names');

export const createTopic = async (config, name, twId) => {
  return apiRequestDev(config, '/data/names/create', 'post', {
    name,
    twId,
  });
};

export const getTopic = async (config, id) => apiRequestDev(config, `/data/names/${id}`);

export const saveTopic = async (config, searchId, twId) => {
  return apiRequestDev(config, '/data/names/save', 'post', {
    data_name: searchId,
    twId,
  });
};

export const deleteTopic = async (config, id) => apiRequestDev(config, `/data/names/${id}/delete`, 'delete');

export const getLatestData = async (config, id) => apiRequest(config, `/data/posts?latest=false&id=${id}`);
