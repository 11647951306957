import { apiRequestDev } from './apiRequest';

export const getAllDistricts = async (config) => apiRequestDev(config, '/districts/all');

export const getDistrictInfo = async (config, id) => apiRequestDev(config, `/districts/info?id=${id}`);

export const createDistrict = async (
  config,
  districtName,
  representative,
  province,
  electionDetails1,
  electionDetails2,
  atlasMapFile,
  detailedMapFile,
) => {
  const formData = new FormData();

  formData.append('districtName', districtName);
  formData.append('representative', representative);
  formData.append('province', province);
  formData.append('electionDetails1', JSON.stringify(electionDetails1));

  if (electionDetails2) {
    formData.append('electionDetails2', JSON.stringify(electionDetails2));
  }

  if (atlasMapFile) {
    formData.append('atlasMap', atlasMapFile);
  }

  if (detailedMapFile) {
    formData.append('detailedMap', detailedMapFile);
  }

  return apiRequestDev(config, '/districts/create', 'post', formData, true);
};

export const saveDistrict = async (
  config,
  id,
  districtName,
  representative,
  province,
  electionDetails1,
  electionDetails2,
  atlasMap,
  detailedMap,
) => {
  const formData = new FormData();

  formData.append('districtName', districtName);
  formData.append('representative', representative);
  formData.append('province', province);
  formData.append('electionDetails1', JSON.stringify(electionDetails1));

  if (electionDetails2) {
    formData.append('electionDetails2', JSON.stringify(electionDetails2));
  }

  formData.append('isNewAtlasMap', atlasMap.file ? true : atlasMap.isNew);
  formData.append('isNewDetailedMap', detailedMap.file ? true : detailedMap.isNew);

  if (atlasMap.file) {
    formData.append('atlasMap', atlasMap.file);
  }

  if (detailedMap.file) {
    formData.append('detailedMap', detailedMap.file);
  }

  return apiRequestDev(config, `/districts/${id}/update`, 'post', formData, true);
};

export const deleteDistrict = async (config, id) => apiRequestDev(config, `/districts/${id}/delete`, 'delete');

export const assignDistrict = async (config, userId, districtId) => {
  return apiRequestDev(config, '/districts/user', 'post', {
    district_id: districtId[0],
    user_id: userId,
  });
};
